import React from 'react'
import { FaTrash } from "react-icons/fa"

const DeleteButton = ({ deleteFunction, children, ...params }) => {    
    return (
        <div className="inline-flex">
            <button {...params}
            className="bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-2 px-2 border border-red-500 hover:border-transparent rounded"
            >
            <div className="flex flex-row items-center justify-center">
                <FaTrash className="md:text-xs lg:text-base"/>
                {children ? <div>&nbsp; {children}</div> : ""}
            </div>
            </button>
        </div>
    )
}

export default DeleteButton