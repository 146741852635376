import React from "react"
import { isMobile } from "../hooks"
import { useSelector } from "react-redux"
import { getCartState } from "../redux/accessors"
import { SiteMetadata } from "../components/Shared"
import { LineItems } from "../components/PageSpecific/cart"
import { Button } from "../components/Global/Gui/Buttons"
import { PageLayout } from "../components/Global/Layouts"

const CartPage = () => {
  const mobile = isMobile()
  const { inCart, checkout } = useSelector(getCartState)

  return (
    <PageLayout>
      <SiteMetadata title="Cart" description="AnnMade Cart Items and Details" />
        <div className="py-12 lg:pb-16 relative">
        <div className="bg-gray-100 absolute -right-full -left-full inset-y-0" />

          <div className="flex flex-wrap z-10 relative">
            <div className="w-full md:w-full xl:w-full md:pb-0">
              <h1 className="text-3xl leading-tight font-extrabold tracking-tight text-gray-600 sm:text-4xl mb-4">
                { inCart > 0 ? `Cart` : `Your cart is empty` }
              </h1>
              <LineItems isMobile={mobile} />
            </div>
          </div>
        </div>
      {inCart > 0 && (
        <div className="container">
          <div className="py-4 flex flex-col items-end justify-between">
            <div>
              <div className="flex flex-row items-center justify-between">
                <div className="text-base text-gray-700 font-medium">
                  Subtotal:
                </div>
                <div className="text-base text-gray-700 font-medium">
                  ${checkout.subtotalPrice}{" "}
                  {checkout.subtotalPriceV2.currencyCode}
                </div>
              </div>
              <div className="text-xs text-gray-700">
                Taxes, Shipping & Discounts calculated
              </div>
              <div className="text-xs text-gray-700">at checkout</div>
            </div>

            <Button
              theme={`LAUNCH-CHECKOUT`}
              href={checkout.webUrl}
              target="_blank"
            >
              Checkout
            </Button>
          </div>
        </div>
      )}
    </PageLayout>
  )
}

export default CartPage
