import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { isMobile } from "../../../hooks"
import LineItem from "./LineItem"
import { useAlert } from "react-alert"
import { getCartState } from "../../../redux/accessors"
import { ArrowLink } from "../../Global/Gui/Links"

const LineItems = () => {
  const mobile = isMobile()
  const alert = useAlert()
  const { posting, posted, error, message, inCart, checkout } = useSelector(
    getCartState
  )

  const [sortedLineItems, updateSortedLineItems] = useState(
    [...checkout.lineItems].sort((a, b) => {
      return b.variant.price - a.variant.price
    })
  )

  useEffect(() => {
    if (posting === true && error === null) {
      alert.info(message)
    }
    if (posting === false && error === null && posted === true) {
      alert.success(message)
    }
    if (posting === false && error === true) {
      alert.error(message)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [posting])

  useEffect(() => {
    updateSortedLineItems(
      [...checkout.lineItems].sort((a, b) => {
        return b.variant.price - a.variant.price
      })
    )
  }, [checkout])
  return (
    <div>
      {inCart > 0 ? (
        <table className="table-auto w-full">
          <thead>
            <tr>
              <th className="text-left py-2 text-gray-600">Details</th>
              {!mobile && (
                <th className="text-center py-2 text-gray-600">Price</th>
              )}
              <th className="text-center py-2 text-gray-600">Quantity</th>
              {!mobile && (
                <th className="text-center py-2 text-gray-600">Remove</th>
              )}
            </tr>
          </thead>
          <tbody>
            {sortedLineItems.map(val => {
              let { price } = val.variant
              return (
                <LineItem
                  isMobile={mobile}
                  id={val.id}
                  key={val.id}
                  price={price * val.quantity}
                  customAttributes={val.customAttributes}
                  title={val.title}
                  quantity={val.quantity}
                />
              )
            })}
          </tbody>
        </table>
      ) : (
        <div>
          <div className="flex flex-row items-center justify-start">
            <h2 className="text-xl leading-tight font-semibold tracking-tight text-annmade-1.0-blue sm:text-2xl">
              <ArrowLink to={"/design-studio"}>...let's change that! </ArrowLink>
            </h2>
          </div>
        </div>
      )}
    </div>
  )
}

export default LineItems
