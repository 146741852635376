import React from "react"
import { Transition } from "../../../styles"

const AddSubtractButtons = ({
  addFunction,
  subtractFunction,
  min,
  max,
  children,
  currVal,
}) => {
  return (
    <div
      className={`flex flex-row items-center justify-evenly w-full content-option`}
    >
      <button
        onClick={subtractFunction}
        disabled={currVal <= min}
        className={`${
          currVal <= min ? "opacity-50 cursor-not-allowed" : ""
        } mr-2 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-2 rounded-l ${
          Transition.DEFAULT
        } content-option`}
      >
        <svg
          stroke="currentColor"
          fill="currentColor"
          stroke-width="0"
          viewBox="0 0 448 512"
          class="content-option"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="content-option"
            d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
          ></path>
        </svg>
      </button>
      {children}
      <button
        onClick={addFunction}
        disabled={currVal >= max}
        className={`${
          currVal >= max ? "opacity-50 cursor-not-allowed" : ""
        } ml-2 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-2 rounded-r ${
          Transition.DEFAULT
        } content-option`}
      >
        <svg
          stroke="currentColor"
          fill="currentColor"
          stroke-width="0"
          viewBox="0 0 448 512"
          class="content-option"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="content-option"
            d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
          ></path>
        </svg>
      </button>
    </div>
  )
}

export default AddSubtractButtons
