import React, { useState, useEffect } from "react"
import { AddSubtractButtons, DeleteButton } from "../cart"
import { MoreInfoModal } from "../../Global/Gui/Modals"
import { useDispatch } from "react-redux"

const LineItem = props => {
  const { customAttributes, title, quantity, price, isMobile, id } = props
  const dispatch = useDispatch()
  const [attributes, updateAttributes] = useState(new Map())
  const [displayModal, toggleModal] = useState(false)

  // Shop ID keys to hide wine glass, shot glass, and beer mug
  const hideAttributes = new Set([
    "Wine Glass", "Beer Mug", "Shot Glass",
  ])

  useEffect(() => {
    customAttributes.forEach(({ key, value }) => {
      if (!attributes.has(key)) {
        updateAttributes(new Map(attributes.set(key, value)))
      }
    })
  }, [attributes, customAttributes])

  return (
    <tr className="border-b-2 border-gray-200">
      <td className="py-2">
        <div className="md:flex-shrink-0">
          <div className="flex flex-row items-center justify-start">
            {attributes.has("_Photo") && (
              <div
                role="button"
                tabIndex={0}
                className={`outline-none rounded-sm w-1/2 sm:w-32 md:w-40 lg:w-40 xl:w-40 ${!isMobile &&
                  "pointer-events-none"}`}
                onClick={() => {
                  isMobile && toggleModal(true)
                }}
                onKeyPress={() => {
                  isMobile && toggleModal(true)
                }}
              >
                <img src={attributes.get("_Photo")} alt="Glass Song Plaque" />
              </div>
            )}
            <div
              className={`flex ${
                attributes.has("_Photo") ? `flex-col` : `flex-row`
              } justify-center`}
            >
              {attributes.has("_Photo") && (
                <h2
                  className={`sm:text-lg text-blue-600 font-semibold ${
                    attributes.has("_Photo") ? `mx-3` : null
                  }`}
                >
                  {title}
                </h2>
              )}

              {!attributes.has("_Photo") && (
                <div
                  role="button"
                  tabIndex={0}
                  className={`outline-none ${!isMobile &&
                    "pointer-events-none"}`}
                  onClick={() => {
                    isMobile && toggleModal(true)
                  }}
                  onKeyPress={() => {
                    isMobile && toggleModal(true)
                  }}
                >
                  <h2
                    className={`sm:text-lg text-blue-600 font-semibold ${
                      attributes.has("_Photo") ? `mx-3` : null
                    }`}
                  >
                    {title}
                  </h2>
                </div>
              )}

              {isMobile ? (
                <div className="text-base text-green-money mx-3">${price}</div>
              ) : !hideAttributes.has(id) ? (
                <div className="mx-3">
                  {customAttributes.map((i, index) => {
                    const ignoreAttribute =
                      i.key.charAt(0) === "_" ? true : false
                    if (!ignoreAttribute) {
                      return (
                        <div
                          key={index}
                          className="flex flex-row items-center justify-start"
                        >
                          <div className="text-sm sm:text-base text-gray-800 whitespace-pre-line">
                            {i.key}:
                          </div>
                          &nbsp;
                          <div className="text-sm sm:text-base text-gray-600 whitespace-pre-line">
                            {i.value}
                          </div>
                        </div>
                      )
                    }
                    return null
                  })}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </td>

      {!isMobile && (
        <td>
          <div className="flex flex-col items-center justify-center">
            <div className="text-base text-green-money">${price}</div>
          </div>
        </td>
      )}

      <td>
        <div className="flex flex-col items-center justify-center">
          <AddSubtractButtons
            subtractFunction={() =>
              dispatch({
                type: "UPDATE_LINE_ITEM",
                lineItemID: id,
                variables: {
                  quantity: quantity - 1,
                },
              })
            }
            addFunction={() =>
              dispatch({
                type: "UPDATE_LINE_ITEM",
                lineItemID: id,
                variables: {
                  quantity: quantity + 1,
                },
              })
            }
            min={1}
            max={10}
            currVal={quantity}
          >
            <div className="text-base text-gray-600 whitespace-pre-line">
              {quantity}
            </div>
          </AddSubtractButtons>
        </div>
      </td>

      {!isMobile && (
        <td>
          <div className="flex flex-col items-center justify-center">
            <DeleteButton
              onClick={() =>
                dispatch({ type: "REMOVE_LINE_ITEM_IN_CART", lineItemID: id })
              }
            ></DeleteButton>
          </div>
        </td>
      )}

      {displayModal && isMobile && (
        <MoreInfoModal title={title} open={displayModal} setOpen={toggleModal}>
          <table className="table-auto w-full">
            <thead>
              <tr>
                {customAttributes.length > 0 && !hideAttributes.has(title) && (
                  <th className="text-left py-1 text-gray-500">Details</th>
                )}
                <th className="text-left py-1 text-gray-500">Remove</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {customAttributes.length > 0 && !hideAttributes.has(title) && (
                  <td>
                    {customAttributes.map((i, index) => {
                      const ignoreAttribute =
                        i.key.charAt(0) === "_" ? true : false
                      if (!ignoreAttribute) {
                        return (
                          <div
                            key={index}
                            className={`flex flex-row items-center justify-start`}
                          >
                            <div className="text-sm sm:text-base text-gray-800 whitespace-pre-line">
                              {i.key}:
                            </div>
                            &nbsp;
                            <div className="text-sm sm:text-base text-gray-600 whitespace-pre-line">
                              {i.value}
                            </div>
                          </div>
                        )
                      }
                      return null
                    })}
                  </td>
                )}
                <td>
                  <div className="flex flex-col items-start justify-center">
                    <DeleteButton
                      onClick={() => {
                        dispatch({
                          type: "REMOVE_LINE_ITEM_IN_CART",
                          lineItemID: id,
                        })
                        toggleModal(!displayModal)
                      }}
                    ></DeleteButton>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </MoreInfoModal>
      )}
    </tr>
  )
}
export default LineItem
